a#skip-link {
  position:fixed;
  left:-999px;
  top: 0;
  z-index:1001;
  width:1px;
  height:1px;
  overflow:hidden;
  &:active, &:focus {
    top:0;
    left: 0;
    width: auto;
    height: auto;
    overflow:auto;
  }
}
header.banner {
  @include color-block('white');
/*
  @include color-block('teal-light');
  @include media-breakpoint-up(md) {
    @include color-block('white');
  }
*/
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  height: $header-height-sm;
  @include media-breakpoint-up(md) {
    height: $header-height;
  }
  .logged-in.admin-bar & {
    top: $adminbar-height;
    @media screen and (max-width: 782px) {
      top: $adminbar-height-sm;
    }
  }
  .brand-wrap {
    position: absolute;
    width: 100%;
    z-index: 100;
    .brand {
      display: block;
      img {
        width: 100%;
      }
    }
    @include media-breakpoint-down(sm) {
      @include color-block('teal-light');
      &>.container {
        &>.row {
          height: $header-height-sm;
          align-items: center;
          &>.d-md-none {
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
      .brand {
        width: 78px;
      }
      .phone-number {
        @include transition($link-transition);
        @include scaled-spacer('margin','right','md');
        color: $brand-primary;
        width: $font-size-xl;
        position: relative;

        i {
          @include transition($link-transition);
          font-size: $font-size-xl;
          &.fa-regular {
            opacity: 1;
          }
          &.fa-solid {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }
        }
        @include hover-focus {
          i {
            &.fa-regular {
              opacity: 0;
            }
            &.fa-solid {
              opacity: 1;
            }
          }
        }
      }
      .navbar-toggler {
        position: relative;
        border: none;
        background-color: transparent;
        padding: 0;
        width: $font-size-xl;
        height: $font-size-xl;
        color: $brand-primary;
        i {
          font-size: $font-size-xl;
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          &.fa-xmark {
            @include transition($link-transition-bars);
      			transform: rotate(-180deg) scale(.5);
      			opacity: 0;
      			left: 2px;
          }
          &.fa-bars {
            @include transition($link-transition-bars);
          }
        }
        .primary-nav-engaging & {
          i {
            &.fa-xmark {
              transform: rotate(0deg) scale(1);
        			opacity: 1;
            }
            &.fa-bars {
              transform: rotate(180deg) scale(.5);
        			opacity: 0;
            }
          }
        }
      }
    }
    @include media-breakpoint-up(md) {
      &>.container {
        position: relative;
        &>.row {
          position: absolute;
          top: 0;
          left: $grid-gutter-width/2;
        }
      }
      .brand {
        width: 103px;
      }

    }
  }
  #nav-wrapper {
    flex-direction: column;
    @include media-breakpoint-down(sm) {
      margin-top: $header-height-sm;
    }
    &.navbar-collapse {
      align-items: normal;
    }
  }
  .primary-wrap {
    @include color-block('white');
    font-size: $font-size-sm;
    order: 0;
    @include media-breakpoint-up(md) {
      order: 1;
    }
    @include media-breakpoint-up(lg) {
      font-size: $font-size-md;
    }
    .nav-primary {
      @include media-breakpoint-up(md) {
        @include nav-horizontal;
        @include scaled-spacer('margin','top','xs-sm');
        justify-content: flex-end;
        align-items: center;
        &>ul {
          &>li {
            @include scaled-spacer('padding','top','xs-sm');
            &:first-child {
              padding-left: 0;
            }
            &>a {
              border-top: 3px solid transparent;
              color: $brand-primary;
              padding: 6px 0;
              font-weight: $font-weight-bold;
              position: relative;
              &:before,&:after {
                @include transition($link-transition);
                position: absolute;
                top: 0;
                width: 3px;
                height: 8px;
                background-color: transparent;
                content: " ";
              }
              &:before {
                left: 0;
              }
              &:after {
                right: 0;
              }
              @include hover-focus {
                text-decoration: none;
                border-top-color: $yellow;
                &:before,&:after {
                  background-color: $yellow;
                }
              }
            }
            ul.sub-menu {
              display: none !important;
            }
            &.active {
              &>a {
                border-top: 3px solid $yellow;
                &:before,&:after {
                  position: absolute;
                  top: 0;
                  width: 3px;
                  height: 8px;
                  background-color: $yellow;
                  content: " ";
                }
                &:before {
                  left: 0;
                }
                &:after {
                  right: 0;
                }
              }
            }
          }
        }
      }
      @include media-breakpoint-only(md) {
        &>ul {
          &>li {
            @include scaled-spacer('padding','top','sm-sm');
            @include scaled-spacer('padding','bottom','xxs');
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
        &>ul {
          @include list-normalize;
          @include scaled-spacer('margin','top','md');
          @include scaled-spacer('margin','bottom','md');
          border-top: 1px solid $yellow;
          display: block !important;
          width: 100%;
          li {
            a {
              position: relative;
              &:after {
                font: var(--fa-font-solid);
                font-size: $font-size-lg;
                color: $yellow;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            &:not(.menu-item-has-children) {
              a {
                &:after {
                  font: var(--fa-font-regular);
                  content: "\f054";
                }
              }
            }
            &.menu-item-has-children {
              &>a {
                &:after {
                  content: "\f055";
                }
              }
              &>ul.sub-menu {
//                 @include transition($link-transition);
                display: none;
              }
              &.show-sub-menu {
                &>a {
                  &:after {
                    content: "\f056";
                  }
                }
/*
                &>ul.sub-menu {
                  display: block !important;
                }
*/
              }
            }
          }
          &>li {
            border-bottom: 1px solid $yellow;
            line-height: 1;
            &>a {
              @include scaled-spacer('padding','top','sm');
              @include scaled-spacer('padding','bottom','sm');
              @include scaled-spacer('padding','left','xs');
              @include scaled-spacer('padding','right','md-lg');
              display: block;
              width: 100%;
              font-size: $font-size-base;
              font-weight: $font-weight-bold;
              color: $brand-primary;
              &:after {
                font-size: $font-size-xl;
                right: 8px;
              }
            }
            &>ul.sub-menu {
              @include list-normalize;
              @include scaled-spacer('margin','top','sm');
              @include scaled-spacer('margin','bottom','sm');
              width: 100%;
              &>li {
                @include scaled-spacer('margin','top','xs');
                @include scaled-spacer('margin','bottom','xs');
                &>a {
                  @include color-block('gray-lightest');
                }
                a {
                  @include scaled-spacer('padding','top','xs');
                  @include scaled-spacer('padding','bottom','xs');
                  @include scaled-spacer('padding','left','sm');
                  @include scaled-spacer('padding','right','md-lg');
                  display: block;
                  width: 100%;
                  color: $body-color;
                  font-size: $font-size-base;
                  &:after {
                    right: 16px;
                  }
                }
                &>ul.sub-menu {
                  @include list-normalize;
                  @include scaled-spacer('margin','top','xs');
                  @include scaled-spacer('margin','bottom','xs');
                  width: 100%;
                  &>li {
                    &>a {
                    }
                  }
                }
              }
            }
          }
        }
      }
      .contact-btn {
        @include media-breakpoint-down(md) {
          display: none;
        }
        @include scaled-spacer('margin','top','xs-sm');
        @include scaled-spacer('margin','left','xs-xx');
        @include media-breakpoint-up(xl) {
          @include scaled-spacer('margin','left','xs-sm');
        }
      }
    }
  }
  .ancillary-wrap {
    order: 1;
    @include media-breakpoint-up(md) {
      order: 0;
    }
    @include color-block('teal-light');
    font-size: $font-size-xs;
    @include media-breakpoint-up(lg) {
      font-size: $font-size-sm;
    }
    &>.container {
      &>.row {
        @include media-breakpoint-up(md) {
          height: $ancillary-wrap-height;
        }
        align-items: center;
      }
    }
    .ancillary-items {
      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
        padding: 6px 0;
        .search-form,.nav-ancillary {
          margin: 6px auto;
        }
      }
      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }
    .nav-ancillary {
      @include nav-piped;
      @include allcaps;
      ul {
        li {
          border-color: $brand-primary;
          a {
            color: $brand-primary;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        ul {
          li {
            &:first-child {
              padding-left: 0;
              border: none;
            }
            &:nth-last-child(2) {
              padding-right: 0;
            }
          }
        }
      }
    }
    .search-form {
      input {
        order: 1;
        outline: none;
        border: none;
        border-bottom: 1px solid $yellow;
        background: none;
        width: $searchbar-width;
        padding: 0;
        color: $brand-primary;
        float: left;
        font-size: $font-size-xs;
        @include media-breakpoint-up(md) {
          width: 0;
        }
        @include media-breakpoint-up(lg) {
          font-size: $font-size-sm;
        }
        transition: .5s;
        line-height: 1.2;
        &:focus,
        &:not(:placeholder-shown) {
          @include media-breakpoint-up(md) {
            @include scaled-spacer('margin','right','xs-sm');
            width: $searchbar-width-md;
          }
          @include media-breakpoint-up(lg) {
            width: $searchbar-width;
          }
        }
        &::-webkit-input-placeholder {
          color: $brand-primary;
          text-transform: uppercase;
          font-weight: $font-weight-bold;
    		}
    		&:-moz-placeholder { /* Firefox 18- */
          color: $brand-primary;
          text-transform: uppercase;
          font-weight: $font-weight-bold;
    		}
    		&::-moz-placeholder {  /* Firefox 19+ */
          color: $brand-primary;
          text-transform: uppercase;
          font-weight: $font-weight-bold;
    		}
    		&:-ms-input-placeholder {
          color: $brand-primary;
          text-transform: uppercase;
          font-weight: $font-weight-bold;
    		}
        &:focus {
      		&::-webkit-input-placeholder {
      		  color: transparent;
      		}
      		&:-moz-placeholder { /* Firefox 18- */
      		  color: transparent;
      		}
      		&::-moz-placeholder {  /* Firefox 19+ */
      		  color: transparent;
      		}
      		&:-ms-input-placeholder {
      		  color: transparent;
      		}
      	}
      }
      button {
        @include scaled-spacer('padding','left','xs-sm');
        @include scaled-spacer('padding','right','xs-sm');
        order: 0;
        color: $brand-primary;
        float: right;
        width: auto;
        height: auto;
        border: none;
        background-color: transparent;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        transition: .3s;
        @include media-breakpoint-down(sm) {
          padding-left: 0;
        }
      }
      @include hover-focus {
        input {
          @include media-breakpoint-up(md) {
            @include scaled-spacer('margin','right','xs-sm');
            width: $searchbar-width-md;
          }
          @include media-breakpoint-up(lg) {
            width: $searchbar-width;
          }

        }
      }
    }
  }
}
body.primary-nav-engaged {
  @include media-breakpoint-down(sm) {
    overflow: hidden;
    header.banner {
      height: 100%;
      overflow: hidden;
      #nav-wrapper {
        height: calc(100% - #{$header-height-sm});
        overflow: scroll;
      }
    }
  }
}
