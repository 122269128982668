.form-control {
  padding: $input-padding-y $input-padding-x !important;
  font-weight: $input-font-weight !important;
  font-size: $input-font-size !important;
  background-color: $input-bg !important;
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
}
.form-control-alt {
  @extend .form-control;
	border: $input-alt-border-width solid $input-alt-border-color;
}
select.form-control {
  @include select-control;
}
label:not(.btn):not(.search-posts-label) {
  @include label;
}
.search-posts {
  label {
    margin: 0;
  }
  .input-group {
    .input-group-append {
      @include scaled-spacer('margin','left','sm');
    }
  }
}


/*
.group-span-filestyle {

  .btn-icon {
    border: 1px solid purple;
  }
}
*/
.gform_wrapper.gravity-theme {
  .gfield_label {
    @include label;
  }
  .gfield {
    @include scaled-spacer('margin','bottom','xs-sm');
    input[type=color],input[type=date],input[type=datetime-local],input[type=datetime],
    input[type=email],input[type=month],input[type=number],input[type=password],input[type=search],
    input[type=tel],input[type=text],input[type=time],input[type=url],input[type=week],
    select,textarea {
      @extend .form-control;
    }
  }
  .gform_footer {
    padding: 0 !important;
    margin: $grid-gutter-width*.75 0 0 !important;
  }
  .ginput_container_fileupload {
    input.large[type=file] {
      width: 80%;
    }
    .btn {
      font-size: $font-size-sm;
      &:after {
        font-size: $font-size-sm;
        top: 48%;
      }
      i {
        margin-right: 5px;
      }
      &.btn-yellow {
        color: $white;
      }
    }
    .gform_fileupload_rules {
      margin-top: 0;
      line-height: 1;
      font-size: $font-size-xs;
    }
  }
}


// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
