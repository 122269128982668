// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$sidebar-lg-columns:    3;

$grid-gutter-width-base:  32px;
$grid-gutter-width:       $grid-gutter-width-base;
$grid-gutter-height:      $grid-gutter-width/2;

$base-gutter-heights: (
  base: $grid-gutter-height
);
$base-gutter-scales: (
  xxs: .25,   // 4
  xs: .5,     // 8
  xs-xx: .625,// 10
  xs-sm: .75, // 12
  sm: 1,      // 16
  sm-sm: 1.25,// 20
  sm-md: 1.5, // 24
  md: 2,      // 32
  md-lg: 2.5, // 40
  lg: 3,      // 48
  lg-xl: 3.5, // 56
  xl: 4,      // 64
  xxl: 5,     // 80
  xxxl: 6,    // 96
  xxxxl: 8,   // 128
);

$ancillary-wrap-height:   40px;
$primary-wrap-height:     86px;
$header-height:           $ancillary-wrap-height + $primary-wrap-height;
$header-height-sm:        53px;
$adminbar-height:         32px;
$adminbar-height-sm:      46px;
$searchbar-width:         240px;
$searchbar-width-md:      130px;




/* COLORS
  --------------------------------------- */

$green:                 #05352F;
$teal:                  #0E666C;
$teal-light:            #E6F4F2;
$teal-lightest:         #F1F7F6;
$yellow:                #F1B92A;
$yellow-alt:            #FFF6DE;
$cream:                 #FEFAF3;

$black-near:            #292929;

$black:                 #000;
$white:                 #fff;
$gray-lighter:          #E2E2E2;
$gray-lightest:         #F8F8F8;

$body-color:            $black-near;
$body-bg:               $white;

$brand-primary:         $green;
$brand-secondary:       $teal;

$inactive-color:        $gray-lighter;

$link-color:            $teal;
$link-decoration:       none;
$link-hover-color:      $brand-primary;
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;
$link-transition-btn:   padding .15s ease-in-out, transform .15s ease-in-out, all .15s ease-in-out;
$link-transition-move:  right .15s ease-in-out,opacity .1s  ease-in-out;
$link-transition-move-reverse:  left .15s ease-in-out,opacity .1s  ease-in-out;
$link-transition-icon:  opacity .15s;
$link-transition-bars:  opacity .2s, transform .2s;

/* FONTS
  --------------------------------------- */
$font-family-sans:      'Palanquin', Corbel, Verdana, "Verdana Ref", sans-serif;
$font-family-serif:     'Aleo', Rockwell, Hoefler Text, Times New Roman, serif;
$font-family-awesome:   'Font Awesome 6 Pro';
$font-family-base:      $font-family-sans;

$font-size-base:        1rem;       //  18
$font-size-xs:          .6666rem;   //  12
$font-size-sm:          .7777rem;   //  14
$font-size-md:          .8888rem;   //  16
$font-size-lg:          1.1111rem;  //  20
$font-size-xl:          1.5rem;     //  27

$banner-font-size:      3.1111rem;  //  56

$h1-font-size:          2.6666rem;  //  48
$h2-font-size:          2.2222rem;  //  40
$h3-font-size:          1.7777rem;  //  32
$h4-font-size:          1.1111rem;  //  20
$h5-font-size:          .8888rem;   //  16
$h6-font-size:          $h5-font-size;

$line-height-base:      1.5;

$font-weight-light:     300;
$font-weight-normal:    400;
$font-weight-semi:      600;
$font-weight-bold:      700;
$font-weight-black:     900;
$font-weight-base:      $font-weight-normal;

$headings-font-family:  $font-family-serif;
$headings-font-weight:  $font-weight-bold;
$headings-line-height:  1.1;



/* BUTTONS
  --------------------------------------- */
$btn-line-height:                1;
$btn-font-size:                  $font-size-md;
$btn-font-family:                $font-family-serif;
$btn-font-weight:                $font-weight-bold;
$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;
$btn-radius:                     0;
$btn-border:                     2px;

$btn-padding-y:                  .5em;
$btn-padding-x:                  1em;

$btn-primary-color:              $white;
$btn-primary-icon:               $yellow;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $btn-primary-bg;
$btn-primary-color-hover:        $btn-primary-color;
$btn-primary-icon-hover:         $btn-primary-icon;
$btn-primary-bg-hover:           $btn-primary-bg;
$btn-primary-border-hover:       $btn-primary-bg-hover;
$btn-primary-border-active:      $btn-primary-icon-hover;

$btn-secondary-color:            $white;
$btn-secondary-icon:             $yellow;
$btn-secondary-bg:               $brand-secondary;
$btn-secondary-border:           $btn-secondary-bg;
$btn-secondary-color-hover:      $btn-secondary-color;
$btn-secondary-icon-hover:       $btn-secondary-icon;
$btn-secondary-bg-hover:         $btn-secondary-bg;
$btn-secondary-border-hover:     $btn-secondary-bg-hover;
$btn-secondary-border-active:    $btn-secondary-icon-hover;

$btn-yellow-color:               $brand-primary;
$btn-yellow-icon:                $btn-yellow-color;
$btn-yellow-bg:                  $yellow;
$btn-yellow-border:              $btn-yellow-bg;
$btn-yellow-color-hover:         $btn-yellow-color;
$btn-yellow-icon-hover:          $btn-yellow-icon;
$btn-yellow-bg-hover:            $btn-yellow-bg;
$btn-yellow-border-hover:        $btn-yellow-bg-hover;
$btn-yellow-border-active:       $btn-yellow-icon-hover;


/* FORM INPUTS
  --------------------------------------- */
$input-font-size:                $font-size-md;
$input-font-weight:              $font-weight-base;
$input-line-height:              $btn-line-height;
$input-height:                   auto;
$label-font-size:                $font-size-md;
$label-margin-bottom:            .5rem;
$input-padding-y:                $btn-padding-y;
$input-padding-x:                $btn-padding-y*1.25;

$custom-select-feedback-icon-padding-right: 0;
$custom-select-feedback-icon-position:      0;
$custom-select-padding-x:                   0;
$custom-select-indicator-padding:           0;

$input-bg:                       $white;
$input-disabled-bg:              $gray-lighter;
$input-color:                    $body-color;
$input-border-color:             $brand-secondary;
$input-border-width:             3px;
$input-alt-border-color:         $yellow;
$input-alt-border-width:         1px;
$input-box-shadow:               none;

$input-border-radius:            $btn-radius;
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;

$input-focus-border-color:       $brand-primary;
$input-focus-box-shadow:         none;

$input-placeholder-color:        rgba($input-color,.6);
$input-plaintext-color:          $input-placeholder-color;

// Badges

$badge-font-size:                $font-size-xs;
$badge-padding-y:                0;
$badge-padding-x:                .4em;
$badge-border-radius:            2px;

