body.search {
  h1.entry-title {
    @include scaled-spacer('margin','bottom','lg');
  }
  article.hentry {
    @include scaled-spacer('margin','bottom','md-lg');
    h2.entry-title {
      @include resize-header('h3');
      @include scaled-spacer('margin','bottom','xs');
    }
    .entry-summary {
      p {
        @include scaled-spacer('margin','bottom','xs');
      }
    }
  }
}
