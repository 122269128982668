.btn,.btn:not(:disabled):not(.disabled),.gform_button {
  @include transition($link-transition-btn);
  box-shadow: $btn-box-shadow !important;
  padding: $btn-padding-y $btn-padding-x;
  font: $btn-font-weight $btn-font-size $btn-font-family;
  line-height: $btn-line-height;
  border-radius: $btn-radius;
  border: $btn-border solid $btn-primary-border;
  color: $btn-primary-color;
  background-color: $btn-primary-bg;
  position: relative;
  .fa {
    color: $btn-primary-bg;
  }
  .badge {
    margin-left: 5px;
  }
  &:after {
    @include transition($link-transition-move);
    font: $font-weight-base $btn-font-size $font-family-awesome;
    color: $btn-primary-icon;
    content: "\f105";
    position: absolute;
    opacity: 0;
    right: $btn-padding-x;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 1px;
  }
  &:hover,&:active,&:focus {
    color: $btn-primary-color-hover;
    background-color: $btn-primary-bg-hover;
    border-color: $btn-primary-border-hover;
    padding: $btn-padding-y $btn-padding-x*1.4 $btn-padding-y  $btn-padding-x*.6;
    &:after {
      opacity: 1;
      color: $btn-primary-icon-hover;
      right: $btn-padding-x*.5;
    }
  }
  &:active,&:focus {
    border-color: $btn-primary-border-active;
  }
  &.btn-reverse {
    &:after {
      @include transition($link-transition-move-reverse);
      content: "\f104";
      left: $btn-padding-x;
      right: auto;
    }
    &:hover,&:active,&:focus {
      padding: $btn-padding-y $btn-padding-x*.6 $btn-padding-y $btn-padding-x*1.4;
      &:after {
        left: $btn-padding-x*.5;
        right: auto;
      }
    }
  }
  &.btn-secondary {
    color: $btn-secondary-color;
    background-color: $btn-secondary-bg;
    border-color: $btn-secondary-border;
    &:after {
      color: $btn-secondary-icon;
    }
    &:hover,&:active,&:focus {
      color: $btn-secondary-color-hover;
      background-color: $btn-secondary-bg-hover;
      border-color: $btn-secondary-border-hover;
      &:after {
        opacity: 1;
        color: $btn-secondary-icon-hover;
      }
    }
    &:active,&:focus {
      border-color: $btn-secondary-border-active;
    }
    .badge {
      background-color: $teal-light;
    }

  }
  &.btn-yellow {
    color: $btn-yellow-color;
    background-color: $btn-yellow-bg;
    border-color: $btn-yellow-border;
    &:after {
      color: $btn-yellow-icon;
    }
    &:hover,&:active,&:focus {
      color: $btn-yellow-color-hover;
      background-color: $btn-yellow-bg-hover;
      border-color: $btn-yellow-border-hover;
      &:after {
        opacity: 1;
        color: $btn-yellow-icon-hover;
      }
    }
    &:active,&:focus {
      border-color: $btn-yellow-border-active;
    }
  }
  &.btn-icon {
    border-width: 1px;
    font: $font-weight-black $btn-font-size $font-family-awesome;
    padding: $btn-padding-y !important;
    line-height: 1 !important;
    width: $btn-font-size*2.5;
    height: $btn-font-size*2;
    i {
      transition: all ease-in .1s;
    }
    &:after {
      content: "" !important;
      display: none !important;
    }
    &:hover,&:active,&:focus {
      i {
        transform: scale(1.25);
      }
    }
    &:active,&:focus {
      color: $btn-primary-bg;
      border-color: $btn-primary-bg;
      background-color: $white;
    }
    &.btn-secondary {
      &:active,&:focus {
        color: $btn-secondary-bg;
        border-color: $btn-secondary-bg;
      }
    }
    &.btn-yellow {
      color: $white;
      &:active,&:focus {
        color: $btn-yellow-bg;
        border-color: $btn-yellow-bg;
      }
    }
    &:disabled,&.disabled {
      background-color: $inactive-color !important;
      color: $white !important;
    }
    &.btn-icon-sm {
      width: $btn-font-size*1.5;
      padding-left: .25em !important;
      padding-right: .25em !important;
    }
  }
  &.btn-search {
    padding: 10px;
    border-radius: 50%;
    aspect-ratio: 1;
    font-size: $font-size-base;
    &:after {
      display: none !important;
    }
    &:hover,&:active,&:focus {
      color: $btn-primary-color-hover;
      background-color: $btn-primary-bg-hover;
      border-color: $btn-primary-border-hover;
    }
  }

}
.btn {
  &:disabled,&.disabled {
    background-color: $white !important;
    border-color: $inactive-color !important;
    color: $inactive-color !important;
    opacity: 1 !important;
  }
}

