.team-widget {
  @include full-padding-spacer('sm');
  @include color-block('gray-lightest');
  h3 {
    font-size: $font-size-xl;
    margin-bottom: 0;
    a.staff-groups-toggle {
      display: block;
      @include hover-focus {
        text-decoration: none;
      }
      i {
        float: right;
        &.fa-circle-minus {
          display: none;
        }
      }
    }
  }
  .staff-groups {
    display: none;
    .staff-group {
      @include list-normalize;
      @include scaled-spacer('margin','top','sm-md');
      li {
        @include scaled-spacer('margin','bottom','xxs');
        a {
          color: $green !important;
        }
      }
      h4 {
        @include sans-text;
        @include allcaps;
        font-size: $font-size-md;
        margin-bottom: 0;
      }
    }
  }
}
