.slick-arrows {
  width: 100%;
  ul {
    @include list-normalize;
    @include list-inline-block;
    text-align: right;
    li {
      button {
        margin-top: -1px;
      }
    }
  }
}
.cta-slider {
  visibility: hidden;
  &.slick-initialized {
    visibility: visible;
  }
}
