// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(md) {
    @include make-col($sidebar-sm-columns);
  }
  @include media-breakpoint-up(lg) {
    @include make-col($sidebar-lg-columns);
    @extend .offset-lg-1;
  }
}
body:not(.home):not(.single-team):not(.blog):not(.archive.category):not(.archive.author) {
  .main {
    @include main-column-width;
  }
}
