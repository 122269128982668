.page-header {
  @include hero-block;
  body:not(.home) & {
    align-items: flex-end;
    min-height: 160px;
    @include media-breakpoint-up(md) {
      padding-bottom: 0;
      min-height: 500px;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 0;
      min-height: 550px;
    }
  }
  &:not(.hero) {
    @include color-block('green');
    min-height: 235px !important;
    align-items: center;
    &:before {
      opacity: 0.15;
    }
    h1.page-title {
      @include heading-punctuation;
      margin: .5em 0;
    }
  }

/*
  position: relative;
  align-items: flex-end;
  min-height: 160px;
  @include media-breakpoint-up(sm) {
    min-height: 220px;
  }
  @include media-breakpoint-up(md) {
    @include scaled-spacer('padding','top','lg');
    min-height: 500px;
  }
  @include media-breakpoint-up(xl) {
    @include scaled-spacer('padding','top','xl');
    min-height: 550px;
  }
  &:before {
    content: "";
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  &:not(.hero) {
    @include color-block('green');
    min-height: 235px;
    align-items: center;
    &:before {
      opacity: 0.15;
    }
    h1.page-title {
      @include heading-punctuation;
      margin: .5em 0;
    }
  }
  .hero-message {
    @include scaled-spacer('padding','left','md');
    @include scaled-spacer('padding','right','md');
    @include last-child-zero-margin;
  }
  .home & {
    min-height: 170px;
    align-items: center;
    @include media-breakpoint-up(md) {
      @include scaled-spacer('padding','bottom','xl');
      min-height: 530px;
    }
    @include media-breakpoint-up(xl) {
      @include scaled-spacer('padding','bottom','xxl');
      min-height: 585px;
    }
  }
*/
}
.hero-message {
  @include scaled-spacer('padding','top','md');
  @include scaled-spacer('padding','bottom','md');
  @include color-block('white');
  background-color: rgba($white,.9);
  p {
    @include scaled-spacer('margin','bottom','sm');
    &.hero-title {
      @include heading-punctuation;
      @include serif-text;
      font-size: $h2-font-size;
      color: $brand-primary;
      font-weight: $font-weight-light;
      line-height: 1.2;
      @include media-breakpoint-up(xl) {
        font-size: $h1-font-size;
      }
    }
    &.overline {
      @include allcaps;
      font-size: $font-size-md;
    }
  }
  .ctas {
    @include list-normalize;
    @include scaled-spacer('margin','top','sm-md');
    li {
      @include scaled-spacer('margin','right','sm-sm');
      display: inline-block;
    }
  }
  b,strong {
    font-weight: $font-weight-bold;
  }
  .col-12 {
    @include last-child-zero-margin;
  }
}
.breadcrumb-share {
  @include scaled-spacer('padding','top','sm-md');
//   @include scaled-spacer('padding','bottom','sm-md');
  @include allcaps;
  justify-content: space-between;
  align-items: center;
  #breadcrumbs {
    max-width: 55%;
    font-weight: $font-weight-semi;
    font-size: $font-size-xs;
    margin: 0;
    a {
      color: $body-color;
    }
  }
  .sharedaddy {
    .sd-social-icon {
      @extend .d-flex;
      align-items: center;
      margin-bottom: 0;
      h3.sd-title {
        @include label;
        @include sans-text;
        margin: 0;
        &:before {
          display: inline;
          width: auto;
          min-width: 0;
          border: none;
        }
      }
      .sd-content {
        .share-customize-link {
          display: none !important;
        }
        ul {
          li[class*='share-'] {
            margin: 0;
            @include scaled-spacer('margin','left','sm');
            a {
              background-color: transparent !important;
              padding: 0;
              font-size: $font-size-base;
              @include hover-focus {
                opacity: 1;
              }
              &:before {
                font: var(--fa-font-brands);
              }
            }
            &.share-facebook {
              a {
                color: $brand-secondary !important;
                @include hover-focus {
                  color: $yellow !important;
                }
                &:before {
                  content: "\f39e";
                }
              }
            }
            &.share-linkedin {
              a {
                color: $brand-secondary !important;
                @include hover-focus {
                  color: $yellow !important;
                }
                &:before {
                  content: "\f0e1";
                }
              }
            }
            &.share-email {
              a {
                color: $brand-secondary !important;
                @include hover-focus {
                  color: $yellow !important;
                }
                &:before {
                  font: var(--fa-font-solid);
                  content: "\f0e0";
                }
              }
            }

          }
        }
      }
    }
  }
}
