.wp-full-width {
  @include wp-full-width-no-padding;
}
.gs-block {
  @include scaled-spacer('padding','top','md-lg');
  @include scaled-spacer('padding','bottom','md-lg');
  @include scaled-spacer('margin','top','md-lg');
  @include scaled-spacer('margin','bottom','md-lg');
  @include media-breakpoint-up(md) {
    @include scaled-spacer('padding','top','xl');
    @include scaled-spacer('padding','bottom','xl');
    @include scaled-spacer('margin','top','xl');
    @include scaled-spacer('margin','bottom','xl');
  }
  &+.gs-block {
    @include scaled-spacer-negative('margin','top','md-lg');
    @include media-breakpoint-up(md) {
      @include scaled-spacer-negative('margin','top','xl');
    }
  }
}
[class*="end-cap-bg-"] {
  border-bottom-width: 10px;
  border-bottom-style: solid;
  position: relative;
  &:before,&:after {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: $grid-gutter-width*.75;
    content: " ";
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
  &+.cta-slideshow-block {
    padding-top: 0;
  }
}
.bg-decorative-img {
  background-image: url(../images/bg-decorative.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  @include media-breakpoint-up(lg) {
    background-position: left bottom;
    background-size: contain;
  }
}
main.main {
  :first-child {
    &.gs-block {
      margin-top: 0;
      &.bg-white,&.form-columns-block,&.cta-slideshow-block {
        padding-top: 0;
      }
    }
    &.page-title {
      &+.gs-block {
        margin-top: 0;
        &.bg-white,&.form-columns-block {
          padding-top: 0;
        }
      }
    }
  }
  :last-child {
    &.gs-block {
      margin-bottom: 0;
      @include scaled-spacer-negative('margin','bottom','md-lg');
      @include media-breakpoint-up(md) {
        @include scaled-spacer-negative('margin','bottom','xxl');
/*
        &.cta-slideshow-block.banner-block {
          padding-bottom: 0;
        }
*/
      }
    }
  }
}
.form-columns-block {
  @include color-block('white');
  .form-column, .side-column {
    &>h2,&>h3 {
      @include scaled-spacer('margin','bottom','sm-md');
    }
    &>h2 {
      @include media-breakpoint-up(md) {
        @include scaled-spacer('margin','bottom','md-lg');
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .form-column {
      @include scaled-spacer('margin','bottom','sm-md');
    }
  }
  .side-column {
    ul.contact-links {
      @include list-normalize;
      li {
        @include scaled-spacer('margin','bottom','xs-sm');
        .icon-link {
          font-size: $font-size-md;
          line-height: $font-size-lg;
          text-decoration: none;
          i {
            @include scaled-spacer('margin','right','xs');
            font-size: $font-size-lg;
          }
        }
        .btn {
          @include scaled-spacer('margin','top','sm');
        }
      }
    }
  }
}
.down-arrow-block {
  h2 {
    @include scaled-spacer('margin','bottom','sm-md');
  }
  article.details {
    @include last-child-zero-margin;
    @include scaled-spacer('padding','left','md');
    @include media-breakpoint-up(lg) {
      @include scaled-spacer('padding','left','lg');
    }
    @include media-breakpoint-up(xl) {
      @include scaled-spacer('padding','left','xl');
    }
    background: url(../images/down-arrow.png) no-repeat left bottom;
    background-size: 20px auto;
    font-size: $font-size-lg;
    ul {
      @include list-image;
      @include paragraph-spacing;
    }
  }
  p.cta {
    @include scaled-spacer('margin','top','sm-md');
    margin-bottom: 0;
  }
  .row {
    &.columns {
      article.details {
        @include scaled-spacer('margin','bottom','lg-xl');
        @include media-breakpoint-up(md) {
          column-count: 2;
          column-gap: $grid-gutter-width;
        }
      }
    }
    &.logos {
      @include scaled-spacer('margin','bottom','lg-xl');
      article.media {
        @include scaled-spacer('margin','top','md-lg');
        @include media-breakpoint-up(md) {
          @include scaled-spacer('padding','left','sm');
          margin-top: 0;
        }
        @include media-breakpoint-up(lg) {
          @include scaled-spacer('padding','left','sm-md');
        }
        @include media-breakpoint-up(xl) {
          @include scaled-spacer('padding','left','md');
        }
      }
    }
  }
}
.cta-slideshow-block {
  @include color-block('white');
  .cta-slide {
    align-items: center;
    .media,.details {
      @include last-child-zero-margin;
    }
    .media {
      flex-direction: column;
      @include media-breakpoint-down(sm) {
        @include scaled-spacer('margin','bottom','sm');
      }
      @include media-breakpoint-down(xs) {
        padding-left: 0;
        padding-right: 0;
      }
      figure {
        margin-bottom: 0;
        img {

        }
      }
    }
    .details {
      .hero-title {
        @include heading-punctuation;
        @include serif-text;
        font-size: $h2-font-size;
        color: $brand-primary;
        font-weight: $font-weight-light;
        line-height: 1.2;
        @include media-breakpoint-up(xl) {
          font-size: $h1-font-size;
        }
      }
    }
  }
  &.banner-block {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
    .cta-hero {
      @include media-breakpoint-down(sm) {
        background-image: none !important;
      }
      @include media-breakpoint-up(md) {
        @include hero-block;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 100%;
      }
    }
  }
}
.testimonial-block {
  @include scaled-spacer('padding','top','xxl');
  @include scaled-spacer('padding','bottom','xxl');
  @include media-breakpoint-up(lg) {
    @include scaled-spacer('padding','top','xxxxl');
    @include scaled-spacer('padding','bottom','xxxxl');
  }
  .testimonial,.attribution {
    @include last-child-zero-margin;
    @include serif-text;
    text-align: center;
    line-height: 1.1;
  }
  .testimonial {
    @include resize-header('h3');
    &:before {
      @include scaled-spacer('margin','bottom','md');
      display: block;
      content: "\f10d";
      font: var(--fa-font-solid);
      font-size: $banner-font-size;
    }
  }
  .attribution {
    @include scaled-spacer('margin','top','md-lg');
    font-weight: bold;
  }
}
.key-contacts-block {
  .contacts-title {
    h2 {
      @include scaled-spacer('margin','bottom','sm-md');
      text-align: center;
      @include media-breakpoint-up(md) {
        @include scaled-spacer('margin','bottom','md-lg');
      }
    }
  }
}
.practice-areas-block {
  @include scaled-spacer('margin','top','lg');
  @include scaled-spacer('margin','bottom','lg');
  border-top: 1px solid $yellow;
  .practice-area-category {
    @include expanding-blocks;
    ul {
      @include list-normalize;
      @include scaled-spacer('margin','top','sm');
      @include scaled-spacer('margin','bottom','md');
      display: none;
      li {
        @include color-block('white');
        break-inside: avoid;
        a {
          @include scaled-spacer('padding','top','xs');
          @include scaled-spacer('padding','bottom','xs');
          @include scaled-spacer('padding','left','sm');
          @include scaled-spacer('padding','right','lg');
          display: block;
          color: $body-color !important;
          position: relative;
          &:after {
            font: var(--fa-font-regular);
            content: "\f054";
            font-size: $font-size-lg;
            color: $yellow;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &:nth-child(odd) {
          @include color-block('gray-lightest');
        }
      }
      @include media-breakpoint-up(md) {
        columns: 2;
        column-gap: $grid-gutter-width/2;
      }
      @include media-breakpoint-up(lg) {
        column-gap: $grid-gutter-width;
      }
    }
  }
}
.news-block {
  &>.container {
    &>.row {
      justify-content: center;
    }
  }
  .news-title {
    text-align: center;
  }
  .news-block-cta {
    @include scaled-spacer('margin','top','lg');
    text-align: center;
  }
  article.hentry {
    @include article-tile;
    &:nth-child(3n+1) {
      @include media-breakpoint-only(md) {
        display: none;
      }
    }
  }
}
.icon-tiles {
  @include scaled-spacer('margin','top','sm');
  .icon-tile {
    @include scaled-spacer('margin','bottom','md');
    @include last-child-zero-margin;
    font-size: $font-size-md;
    p,h3 {
      @include scaled-spacer('margin','bottom','sm');
    }
    p.icon {
      font-size: $h3-font-size;
      line-height: 1;
    }
    h3 {
      @include label;
    }
  }
}
.career-opportunities-block {
  @include scaled-spacer('margin','top','md-lg');
  @include scaled-spacer('margin','bottom','md-lg');
  h2 {
    @include scaled-spacer('margin','bottom','xs');
  }
  .career-opportunities {
    border-top: 1px solid $yellow;
    .career-opportunity {
      @include expanding-blocks;
      div.details {
        @include scaled-spacer('margin','top','sm');
        @include scaled-spacer('margin','bottom','sm-md');
        @include last-child-zero-margin;
        font-size: $font-size-md;
        display: none;
        h4.description-title {
          @include scaled-spacer('margin','bottom','xs-sm');
          @include label;
        }
      }
    }
  }
}
