html {
  font-size: 16px;
  height: 100%;
  scroll-behavior: smooth;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
  body {
    @include color-block('white');
    padding-top: $header-height-sm;
    @include media-breakpoint-up(md) {
      padding-top: $header-height;
    }
  }
}
.wrap {
  body:not(.home) & {
    border-top: 10px solid $brand-secondary;
    position: relative;
    &:before,&:after {
      position: absolute;
      top: 0;
      width: 10px;
      height: $grid-gutter-width*.75;
      background-color: $brand-secondary;
      content: " ";
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
    &.hero-wrap {
      border-top-color: $yellow;
      &:before,&:after {
        background-color: $yellow;
      }
    }
  }
}
main.main,aside.sidebar {
  @include scaled-spacer('padding','top','lg-xl');
  @include scaled-spacer('padding','bottom','md-lg');
  @include media-breakpoint-up(md) {
    @include scaled-spacer('padding','bottom','xxl');
  }
  .home & {
    @include media-breakpoint-down(sm) {
      @include scaled-spacer('padding','top','sm');
    }
  }
}

img {
  @include img-fluid;
}
a {
  @include transition($link-transition);
  &.caret-link {
    @include serif-text;
    font-weight: bold;
    font-size: $font-size-md;
    text-decoration: none;
    i.fa-caret-right {
      @include scaled-spacer('margin','left','xxs');
      @include transition($link-transition);
      font-size: $font-size-base;
      vertical-align: middle;
    }
    @include hover-focus {
      i.fa-caret-right {
        @include scaled-spacer('margin','left','xs');
      }
    }
  }
}
p,ol,ul,blockquote,.wp-block-image {
  @include paragraph-spacing;
}
ul,ol {
  padding-left: 1.5em;
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  @include scaled-spacer('margin','bottom','sm');
}

/*
h2,.h2 {
  font-weight: $font-weight-light;
}
*/
h3,.h3 {
  font-weight: $font-weight-base;
}
h4,.h4,h5,.h5 {
  @include sans-text;
  @include allcaps;
}
