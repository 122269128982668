/* FUNCTIONS
  --------------------------------------- */
@function gutter-scale($size) {
  @return map-get($base-gutter-scales, $size);
}
@function gutter-height($breakpoint) {
  @return map-get($base-gutter-heights, $breakpoint);
}
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}
@function create_color_map($bg, $text, $headline, $link, $hover, $headlinealt, $icon) {
  $map: (
    bg:           $bg,
    text:         $text,
    headline:     $headline,
    link:         $link,
    hover:        $hover,
    headlinealt:  $headlinealt,
    icon:         $icon,
  );
  @return $map;
}

/* HEIGHT SPACERS
  --------------------------------------- */
@mixin scaled-spacer($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: gutter-height(base)*gutter-scale($scale);
}
@mixin scaled-spacer-negative($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: -(gutter-height(base)*gutter-scale($scale)) !important;
}
@mixin scaled-absolute($csslocation,$scale) {
  #{$csslocation}: (gutter-height(base)*gutter-scale($scale));
}
@mixin scaled-absolute-negative($csslocation,$scale) {
  #{$csslocation}: -(gutter-height(base)*gutter-scale($scale));
}
@mixin full-padding-spacer($size) {
  @include scaled-spacer('padding','top',$size);
  @include scaled-spacer('padding','right',$size);
  @include scaled-spacer('padding','bottom',$size);
  @include scaled-spacer('padding','left',$size);
}

/* COLORS
  --------------------------------------- */

/*1 name          2 bgcolor       3 text        4 headline      5 link color      6 hover color         7 headline-alt    8 icon     */
$color-list: (
  white           $white          $body-color   $teal           $link-color       $link-hover-color     $green            $yellow,
  teal            $teal           $white        $white          $teal-lightest    $teal-light           $gray-lightest    $yellow,
  teal-light      $teal-light     $body-color   $teal           $green            $link-color           $green            $yellow,
  teal-lightest   $teal-lightest  $body-color   $teal           $teal             $green                $green            $yellow,
  cream           $cream          $body-color   $teal           $link-color       $link-hover-color     $green            $yellow,
  gray-lightest   $gray-lightest  $body-color   $teal           $link-color       $link-hover-color     $green            $yellow,
  green           $green          $white        $white          $teal-lightest    $teal-light           $yellow           $yellow,
  yellow          $yellow         $body-color   $teal           $link-color       $link-hover-color     $body-color       $white,
  yellow-alt      $yellow-alt     $body-color   $teal           $link-color       $link-hover-color     $body-color       $white,
);

$color-map: ();

@each $value in $color-list {
  .end-cap-bg-#{nth($value, 1)} {
    border-bottom-color: #{nth($value, 2)};
    &:before,&:after {
      background-color: #{nth($value, 2)};
    }
  }
  .bg-#{nth($value, 1)},
  .has-#{nth($value, 1)}-background-color {
    background-color: #{nth($value, 2)};
    color: #{nth($value, 3)};
    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      color: #{nth($value, 4)};
    }
    a {
      color: #{nth($value, 5)};
      @include hover-focus {
        color: #{nth($value, 6)};
      }
    }
    .practice-area-links {
      li {
        a {
          color: #{nth($value, 3)};
        }
      }
    }
    &.team-content {
      .team-row {
        .details {
          h3 {
            a {
              color: #{nth($value, 7)};
              @include hover-focus {
                color: #{nth($value, 4)};
              }
            }
          }
        }
      }
    }
    &.form-columns-block {
      .form-column, .side-column {
        &>h2,&>h3 {
          color: #{nth($value, 7)};
        }
      }
    }
    &.key-contacts-block,&.cta-slideshow-block,&.bio-header {
      h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
        color: #{nth($value, 7)};
      }
    }
    &.down-arrow-block {
      h1,.h1,h2,.h2,h3,.h3 {
        color: #{nth($value, 7)};
      }
    }
    &.profile-card {
      h3.profile-card-title {
        &:before {
          color: #{nth($value, 8)};
        }
      }
    }
    &.testimonial-block {
      .testimonial {
        color: #{nth($value, 7)};
        &:before {
          color: #{nth($value, 8)};
        }
      }
      .attribution {
        color: #{nth($value, 4)};
      }
    }
    .header-color {
      color: #{nth($value, 4)};
    }
    .alt-header-color {
      color: #{nth($value, 7)};
    }
    .fa-icon-color {
      color: #{nth($value, 7)};
    }
    .icon-link {
      i {
        @include transition($link-transition);
        color: #{nth($value, 8)};
      }
      @include hover-focus {
        i {
          color: #{nth($value, 6)};
        }
      }
    }
  }
  $map: ();
  $key: #{nth($value, 1)};
  $map: map-merge($map, ($key: create_color_map(#{nth($value, 2)}, #{nth($value, 3)},#{nth($value, 4)},#{nth($value, 5)},#{nth($value, 6)},#{nth($value, 7)},#{nth($value, 8)})) );
  $color-map: map-merge($color-map, $map);
}
@mixin color-block($block-color) {
  background-color: map-deep-get($color-map, $block-color, "bg");
  color: map-deep-get($color-map, $block-color, "text");
  h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
    color: map-deep-get($color-map, $block-color, "headline");
  }
  a {
    color: map-deep-get($color-map, $block-color, "link");
    @include hover-focus {
      color: map-deep-get($color-map, $block-color, "hover");
    }
  }
  .practice-area-links {
    li {
      a {
        color: map-deep-get($color-map, $block-color, "text");
      }
    }
  }
  &.team-content {
    .team-row {
      .details {
        h3 {
          a {
            color: map-deep-get($color-map, $block-color, "headlinealt");
            @include hover-focus {
              color: map-deep-get($color-map, $block-color, "headline");
            }
          }
        }
      }
    }
  }
  &.form-columns-block {
    .form-column, .side-column {
      &>h2,&>h3 {
        color: map-deep-get($color-map, $block-color, "headlinealt");
      }
    }
  }
  &.key-contacts-block,&.cta-slideshow-block,&.bio-header {
    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      color: map-deep-get($color-map, $block-color, "headlinealt");
    }
  }
  &.down-arrow-block {
    h1,.h1,h2,.h2,h3,.h3 {
      color: map-deep-get($color-map, $block-color, "headlinealt");
    }
  }
  &.profile-card {
    h3.profile-card-title {
      &:before {
        color: map-deep-get($color-map, $block-color, "icon");
      }
    }
  }
  &.testimonial-block {
    .testimonial {
      color: map-deep-get($color-map, $block-color, "headlinealt");
      &:before {
        color: map-deep-get($color-map, $block-color, "icon");
      }
    }
    .attribution {
      color: map-deep-get($color-map, $block-color, "headline");
    }
  }
  .header-color {
    color: map-deep-get($color-map, $block-color, "headline");
  }
  .alt-header-color {
    color: map-deep-get($color-map, $block-color, "headlinealt");
  }
  .fa-icon-color {
    color: map-deep-get($color-map, $block-color, "icon");
  }
  .icon-link {
    i {
      @include transition($link-transition);
      color: map-deep-get($color-map, $block-color, "icon");
    }
    @include hover-focus {
      i {
        color: map-deep-get($color-map, $block-color, "hover");
      }
    }
  }

}



/* ELEMENTS
  --------------------------------------- */
@mixin list-normalize-keep-margin {
  padding: 0;
  list-style: none;
}
@mixin list-normalize {
  @include list-normalize-keep-margin
  margin: 0;
}
@mixin list-inline-block {
  li {
    display: inline-block;
  }
}
@mixin list-image {
  @include list-normalize;
  padding-left: 1.5rem;
  @include media-breakpoint-up(lg) {
    padding-left: 1.4rem;
  }
  li {
    @include scaled-spacer('margin','bottom','xs');
    text-indent: -$grid-gutter-width*.75;
    &:before {
      content: "\f0c8";
      font: $font-weight-bold $font-size-xs $font-family-awesome;
      color: $yellow;
      width: $grid-gutter-width*.75;
      float: left;
      margin-top: 8px;
      @include media-breakpoint-up(md) {
        margin-top: 10px;
      }
    }
  }
}
@mixin last-child-zero-margin {
  &>:last-child {
    margin-bottom: 0;
  }
}
@mixin animate-arrow {
/*
  &:after {
    transform: translateX($arrow-animate-size);
  }
*/
}
@mixin paragraph-spacing {
  margin-bottom: 1.5rem;
}
@mixin select-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right .5rem center;
  background-image: url(../images/circle-chevron-down-solid.svg);
  background-size: auto 2em;
  padding-right: 2.75em !important;
  line-height: 1.81;
}
@mixin standard-block-padding-bottom {
  @include scaled-spacer('margin','bottom','sm-md');
  @include media-breakpoint-up(md) {
    @include scaled-spacer('margin','bottom','md-lg');
  }
}
@mixin standard-block-padding-top {
  @include scaled-spacer('margin','top','sm-md');
  @include media-breakpoint-up(md) {
    @include scaled-spacer('margin','top','md-lg');
  }
}
@mixin dropshadow-box {
  box-shadow: 0px 0px 2px rgba($black, 0.2);
}
@mixin dropshadow-box-sharp {
  box-shadow: 0px 1px 3px 1px rgba($black, 0.15);
}
@mixin tile-hover($bgcolor,$imgcolor) {
  @include color-block($bgcolor);
  @include dropshadow-box;
  display: block;
  figure {
    @include color-block($imgcolor);
    overflow: hidden;
    img {
      transition: all ease-in .1s;
    }
  }
  @include hover-focus {
    box-shadow: 0px 0px 5px rgba($black, 0.2);
    figure {
      img {
        transform: scale(1.015);
        opacity: .98;
      }
    }
  }
}
@mixin article-tile {
  @include scaled-spacer('margin','top','md');
  a.tile-link {
    @include full-padding-spacer('sm-md');
    @include tile-hover('white','green')
    @include last-child-zero-margin;
    @include hover-focus {
      text-decoration: none;
    }
    figure {
      @include scaled-spacer('margin','bottom','xs');
    }
    .category {
      @include scaled-spacer('margin','bottom','sm-md');
      @include label;
    }
    h3 {
      @include scaled-spacer('margin','bottom','sm-md');
      @include sans-text;
      @include article-link-punctuation;
      font-size: $font-size-base;
      font-weight: $font-weight-base;
      line-height: $line-height-base;
      color: $body-color;
    }
    time {
      @include serif-text;
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
    }
  }
}
@mixin gs-pills {
  @include list-normalize;
  li {
    @include scaled-spacer('margin','right','xs-sm');
    @include scaled-spacer('margin','bottom','xs-sm');
    display: inline-block;
    break-inside: avoid;
    a {
      @include scaled-spacer('padding','top','xxs');
      @include scaled-spacer('padding','bottom','xxs');
      @include scaled-spacer('padding','left','xs-sm');
      @include scaled-spacer('padding','right','xs-sm');
      @include color-block('yellow-alt');
      @include sans-text;
      border: 2px solid transparent;
      font-size: $font-size-md;
      display: inline-block;
      border-radius: 4px;
      @include hover-focus {
        @include dropshadow-box-sharp;
        text-decoration: none;
      }
      &.active {
        border-color: $yellow;
        @include hover-focus {
          box-shadow: none;
        }
      }
    }
    &.active {
      a {
        border-color: $yellow;
        @include hover-focus {
          box-shadow: none;
        }
      }
    }
  }
}
@mixin hero-block {
  position: relative;
  align-items: center;
  min-height: 190px;
  @include media-breakpoint-up(sm) {
    min-height: 230px;
  }
  @include media-breakpoint-up(md) {
    @include scaled-spacer('padding','top','lg');
    @include scaled-spacer('padding','bottom','xl');
    min-height: 530px;
  }
  @include media-breakpoint-up(xl) {
    @include scaled-spacer('padding','top','xl');
    @include scaled-spacer('padding','bottom','xxl');
    min-height: 585px;
  }
  &:before {
    content: "";
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .hero-message {
    @include scaled-spacer('padding','left','md');
    @include scaled-spacer('padding','right','md');
    @include last-child-zero-margin;
  }
}
@mixin expanding-blocks {
  border-bottom: 1px solid $yellow;
  h3 {
    margin: 0;
    a {
      @include scaled-spacer('padding','top','sm');
      @include scaled-spacer('padding','bottom','sm');
      @include scaled-spacer('padding','right','md');
      position: relative;
      display: block;
      &:after {
        font: var(--fa-font-solid);
        content: "\f055";
        color: $yellow;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      @include hover-focus {
        text-decoration: none;
      }
    }
  }
  &.active {
    h3 {
      a {
        &:after {
          content: "\f056";
        }
      }
    }
  }
}



/* FONTS
  --------------------------------------- */
@mixin sans-text {
  font-family: $font-family-sans;
}
@mixin serif-text {
  font-family: $font-family-serif;
}
@mixin allcaps {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}
@mixin banner-text {
  font: $font-weight-light $banner-font-size $font-family-serif;
  b,strong {
    font-weight: $font-weight-bold;
  }
}
$heading-sizes: (
  h1: $h1-font-size,
  h2: $h2-font-size,
  h3: $h3-font-size,
  h4: $h4-font-size,
  h5: $h5-font-size,
  h6: $h6-font-size
);
@mixin resize-header($size) {
  font-size: map-get($heading-sizes, $size);
}
@mixin prepend-slashes {
  color: $brand-secondary;
  line-height: $headings-line-height;
  &:before {
    content: "// ";
    color: $yellow;
  }
}
@mixin job-title {
  @include scaled-spacer('padding','top','xxs');
  color: $brand-secondary;
  font-weight: $font-weight-bold;
  background: url(../images/line-job-title.png) no-repeat left top;
  background-size: 32px auto;
  margin-bottom: 0;
  line-height: 1.1;
}
@mixin label {
  @include prepend-slashes;
  @include allcaps;
  font: $font-weight-bold $font-size-md $font-family-sans;
  color: $brand-secondary;
  line-height: $headings-line-height;
  &:before {
    content: "// ";
    color: $yellow;
  }
}
@mixin header-sidecap {
  font: $font-weight-bold $font-size-lg $font-family-sans;
  line-height: $headings-line-height;
  position: relative;
  &:before {
    content: "\f2fa";
    font: var(--fa-font-solid);
    position: absolute;
    top: .2em;
    left: 0;
  }
}
@mixin heading-punctuation {
  &:after {
    @include scaled-spacer('margin','left','xs');
    content: "\f0c8";
    font: $font-weight-bold .25em $font-family-awesome;
    color: $yellow;
  }
}
@mixin article-link-punctuation {
  &:after {
    @include scaled-spacer('margin','left','xs');
    font: var(--fa-font-solid);
    font-size: $font-size-md;
    content: "\f054";
    color: $yellow;
  }
}

/* NAVS
  --------------------------------------- */
@mixin nav-horizontal {
  position: relative;
  overflow: hidden;
  &>ul {
    @include list-normalize;
    @extend .d-flex;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      @include scaled-spacer('padding','left','xs-xx');
      @include scaled-spacer('padding','right','xs-xx');
      @include media-breakpoint-up(xl) {
        @include scaled-spacer('padding','left','xs-sm');
        @include scaled-spacer('padding','right','xs-sm');
      }
      flex-basis: auto;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      text-align: center;
      line-height: 1;
    }
  }
  &:not(.nav-ancillary):not(.nav-primary) {
    &>ul {
      li {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
@mixin nav-piped {
  @include nav-horizontal;
  &>ul {
//     margin-left: -1px;
    li {
      @include scaled-spacer('padding','left','sm');
      @include scaled-spacer('padding','right','sm');
      border-left-width: 1px;
      border-left-style: solid;
      line-height: 1;
    }
  }
  &:not(.nav-ancillary) {
    &>ul {
      li {
        &:first-child {
          border-left: none;
        }
      }
    }
  }
}


/* COLUMNS
  --------------------------------------- */
@mixin wp-full-width-no-padding {
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  margin-left: calc(50% - 50vw);
}
@mixin main-column-width {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
@mixin main-column-width-wide {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}
@mixin main-column-width-full {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12);
    @include make-col-offset(0);
  }
}

