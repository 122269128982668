footer.content-info {
  border-top: $grid-gutter-width/2 solid $gray-lighter;
  color: $brand-primary;
  .footer-wrap {
    flex-direction: column;
    height: 100%;
    .legal {
      margin-top: auto;
    }
  }
  .page-links {
    @include scaled-spacer('padding','top','sm-md');
    nav {
      ul {
        @include list-normalize;
        font-size: $font-size-md;
        font-weight: $font-weight-bold;
        li {
          @include scaled-spacer('margin','top','xs');
          @include scaled-spacer('margin','bottom','xs');
          &:first-child {
            margin-top: 0;
          }
          a {
            color: $brand-primary;
          }
        }
      }
    }
  }
  .description {
    @include scaled-spacer('padding','top','sm-md');
  }
  .contact-info {
    @include media-breakpoint-down(xs) {
      padding-left: 0;
      padding-right: 0;
    }
    article {
      @include color-block('green');
      @include full-padding-spacer('sm-sm');
      @include scaled-spacer('padding','top','md-lg');
      @include scaled-spacer('padding','bottom','md-lg');
      @include media-breakpoint-up(lg) {
        @include scaled-spacer-negative('margin','top','sm');
      }
      &>.row {
        @include media-breakpoint-between(sm,md) {
//           flex-direction: row-reverse;
        }
        &>div {
          @include last-child-zero-margin;
        }
      }
      nav {
        @include scaled-spacer('padding','bottom','sm-md');
        @include media-breakpoint-between(sm,md) {
          padding-bottom: 0;
        }
        ul {
          @include list-normalize;
          font-size: $font-size-md;
          li {
            @include scaled-spacer('margin','top','xs');
            @include scaled-spacer('margin','bottom','xs');
            &:first-child {
              margin-top: 0;
            }
            a {
              text-decoration: none;
              i {
                font-size: $font-size-lg;
                @include scaled-spacer('margin','right','xs-sm');
//                 @include transition($link-transition);
              }
              @include hover-focus {
                  color: $yellow;
                i {
//                   transform: scale(1.125);
                }
              }
              &.phone-number {
                position: relative;
                i {
                  &.fa-regular {
                    opacity: 1;
                  }
                  &.fa-solid {
                    position: absolute;
                    left: 0;
                    top: 5px;
                    opacity: 0;
                  }
                }
                @include hover-focus {
                  i {
                    &.fa-regular {
                      opacity: 0;
                    }
                    &.fa-solid {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
        &.nav-social {
          @include scaled-spacer('padding','bottom','sm');
          ul {
            @include list-inline-block;
          }
        }
      }
    }
  }
  .legal {
    @include scaled-spacer('padding','top','sm-md');
    @include scaled-spacer('padding','bottom','sm-md');
    @include allcaps;
    &>div {
      @include last-child-zero-margin;
      font-size: $font-size-xs;
      nav {
        @include scaled-spacer('margin','bottom','xs');
        @include nav-piped;
        ul {
          @include list-normalize;
          font-size: $font-size-sm;
          li {
            a {
              color: $brand-primary;
            }
            @include media-breakpoint-down(sm) {
              border: none;
              line-height: 1.5;
              padding: 0 1em 0 0;
            }
          }
        }
      }
    }
  }
}
