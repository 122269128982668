body.single-post {
  main.main {
    @include scaled-spacer('margin','top','sm-md');
  }
  .hentry {
    header {
      .categories {
        @include label;
        @include scaled-spacer('margin','bottom','sm');
      }
      .meta-info {
        @include scaled-spacer('margin','bottom','sm');
        @include allcaps;
        font-size: $font-size-xs;
        font-weight: $font-weight-semi;
      }
    }
  }
}
.news-navigations {
  align-items: center;
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
    &>.nav-category,&>form {
      width: 100%
    }
  }
  .nav-category {
    flex-grow: 1;
    @include media-breakpoint-down(sm) {
      @include scaled-spacer('margin','top','xs');
    }
    ul {
      @include gs-pills;
      li {
        @include scaled-spacer('margin','right','xxs');
        @include scaled-spacer('margin','top','xxs');
        @include scaled-spacer('margin','bottom','xxs');
        a {
          font-size: $font-size-sm;
        }
      }
    }
  }
}
.news-items-row {
  article.hentry {
    @include article-tile;
  }
}
body.single-team {
  @include media-breakpoint-down(sm) {
    .row.content {
      main.main {
        order: 0;
      }
      aside.sidebar {
        order: 2;
      }
      section.bio-footer {
        order: 1;
      }
    }
  }
  .bio-header {
    @include color-block('teal-lightest');
    @include scaled-spacer('padding','top','sm-md');
    @include scaled-spacer('padding','bottom','sm-md');
    @include media-breakpoint-up(md) {
      @include scaled-spacer('padding','top','md-lg');
      @include scaled-spacer('padding','bottom','md-lg');
    }
    h1.page-title {
      @include scaled-spacer('margin','bottom','xxs');
    }
    p,ul {
      @include scaled-spacer('margin','bottom','sm');
    }
    p.job-title {
      @include job-title;
    }
    .bio-entry {
      @include scaled-spacer('margin','top','sm');
    }
    .media {
      @include last-child-zero-margin;
    }
    .details {
      @include scaled-spacer('margin','top','sm-md');
      @include last-child-zero-margin;
      h2 {
        @include label;
        @include scaled-spacer('margin','bottom','xxs');
      }
      .practice-area-links {
        @include gs-pills;
        @include scaled-spacer('margin','top','sm');
        @include scaled-spacer('margin','bottom','sm');
        li {
          @include scaled-spacer('margin','right','xs');
          @include scaled-spacer('margin','bottom','xs');
          a {
            @include scaled-spacer('padding','left','xs');
            @include scaled-spacer('padding','right','xs');
            @include color-block('white');
          }
        }

/*
        @include list-normalize-keep-margin;
        li {
          display: inline-block;
          &:not(:last-child) {
            &:after {
              content: ",\00a0";
            }
          }
        }
*/
      }
      .contact-row {
        .links {
          @include media-breakpoint-up(md) {
            @include scaled-spacer('margin','right','md');
          }
          ul {
            @include list-normalize;
            li {
              @include scaled-spacer('margin','right','sm-md');
              display: inline-block;
            }
          }
        }
        .email {
          @include serif-text;
          font-weight: $font-weight-bold;
          @include media-breakpoint-down(sm) {
            @include scaled-spacer('margin','top','sm');
          }
        }
      }
    }
  }
  .profile-card {
    @include scaled-spacer('padding','top','sm-md');
    @include scaled-spacer('padding','bottom','sm-md');
    @include scaled-spacer('padding','left','md');
    @include scaled-spacer('padding','right','md');
    @include scaled-spacer('margin','bottom','md');
    @include color-block('white');
    @include dropshadow-box;
    @include last-child-zero-margin;
    h3.profile-card-title {
      @include header-sidecap;
      &:before {
        left: -32px;
      }
    }
    &>ul {
      @include last-child-zero-margin;
      &>li {
        @include scaled-spacer('margin','bottom','sm');
      }
    }
  }
  .profile-cards {
    @include last-child-zero-margin;
  }
  .bio-footer {
    @include make-col-ready();
    @include make-col(12);
    .publications,.awards {
      @include wp-full-width-no-padding;
      h2 {
        text-align: center;
      }
    }
    .publications {
      @include color-block('teal-lightest');
      @include scaled-spacer('padding','top','lg');
      @include scaled-spacer('padding','bottom','md');
      .showall {
        text-align: center;
      }
      &>.container {
        &>.row {
          justify-content: center;
        }
      }
      .publication {
        @include full-padding-spacer('md');
        font-size: $font-size-md;
        a {
          display: block;
          cursor: pointer;
          text-decoration: none;
        }
        p {
          @include scaled-spacer('margin','bottom','xxs');
          &.author-role {
            @include allcaps;
          }
          &.action {
            @include scaled-spacer('margin','top','xs-sm');
            @include allcaps;
            font-size: $font-size-sm;
          }
        }
        &.additional {
          display: none;
        }
      }

    }
    .awards {
      @include color-block('white');
      @include scaled-spacer('padding','top','xl');
      @include scaled-spacer('padding','bottom','xl');
      .logos {
        justify-content: space-around;
        figure {
          @include full-padding-spacer('sm');
          margin: 0;
          max-width: 232px;
        }
      }
    }
  }
}
.team-grid-block {
  padding-top: 0;
  .team-type {
    @include scaled-spacer('margin','bottom','sm');
    h2 {
      @include prepend-slashes;
      @include resize-header('h3');
      @include media-breakpoint-up(lg) {
        @include scaled-spacer('margin','left','sm');
      }
    }
    ul.industry-filters {
      @include gs-pills;
      @include scaled-spacer('margin','top','sm');
      @include scaled-spacer('margin','bottom','md');
      display: none;
    }
  }
}
.grid-attorneys {
  .team-type {
    h2 {
      display: inline-block;
    }
    &>a {
      @include scaled-spacer('margin','left','sm-md');
      display: inline-block;
      font: $font-weight-bold $font-size-md $font-family-serif;
      &.filter-reset {
        color: $body-color;
        display: none;
      }
      &.filter-toggle {
        i.fa-chevron-down {
          display: none;
        }
        @include hover-focus {
          text-decoration: none;
          span {
            text-decoration: underline;
          }
        }
      }
      i {
        @include scaled-spacer('margin','left','xxs');
        width: 1em;
        text-align: center;
      }
    }
  }
}
.team-card {
  @include scaled-spacer('margin','bottom','md');
  position: relative;
  font-size: $font-size-md;
  @include media-breakpoint-only(lg) {
    font-size: $font-size-sm;
  }
  article.team-content {
    @include color-block('teal-lightest');
    @include dropshadow-box-sharp;
    @include scaled-spacer('padding','bottom','md-lg');
    @include media-breakpoint-down(md) {
      @include scaled-spacer('margin','top','xxxxl');
    }

    @include media-breakpoint-up(lg) {
      @include scaled-spacer('padding','top','md-lg');
      @include scaled-spacer('margin','left','xxxl');
    }
    position: relative;
    .team-row {
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
      @include media-breakpoint-up(lg) {
        @include scaled-spacer-negative('margin','left','xxxl');
      }
      figure {
        @include scaled-spacer('padding','left','sm-md');
        @include scaled-spacer('padding','right','sm-md');
        margin: 0;
        @include media-breakpoint-down(md) {
          @include scaled-spacer-negative('margin','top','xxxxl');
        }
        @include media-breakpoint-up(lg) {
          padding: 0;
          width: 38%;
        }
      }
      .icon-nav {
        @include list-normalize;
        @include scaled-spacer('padding','left','sm-md');
        @include media-breakpoint-up(lg) {
          position: absolute;
          top:0;
          right: 0;
          padding: 0;
        }
        li {
          display: inline;
        }
      }
      .details {
        @include scaled-spacer('padding','left','sm-md');
        @include scaled-spacer('padding','right','sm-md');
        @include media-breakpoint-up(lg) {
          @include scaled-spacer('padding','left','sm');
          @include scaled-spacer('padding','right','sm');
          width: 62%;
        }
        h3 {
          @include scaled-spacer('margin','bottom','xxs');
          a {
            font-weight: $font-weight-base;
            text-decoration: none;
          }
        }
        .job-title {
          @include job-title;
          @include scaled-spacer('margin','bottom','xxs');
          font-size: $font-size-base;
        }
        .email {
          @include scaled-spacer('margin','bottom','xxs');
          @include serif-text;
          font-weight: $font-weight-bold;
          display: block;
          color: $brand-primary;
        }
        h4 {
          @include label;
          @include scaled-spacer('margin','top','xs-sm');
          @include scaled-spacer('margin','bottom','xs');
        }
        .practice-area-links {
          margin-bottom: 0;
        }
        .learnmore {
          @include scaled-spacer('margin','top','sm');
          @include serif-text;
          font-weight: $font-weight-bold;
          display: block
        }
      }
    }
  }
}


/* NAVS
  --------------------------------------- */
nav.posts-navigation {
  @include scaled-spacer('margin','top','xl');
  @include color-block('white');
  .nav-links {
    @include make-row();
    &>div {
      @include make-col-ready();
      @include make-col(6);
      &.nav-next {
        margin-left: auto;
        text-align: right;
      }
      a {
        @extend .btn;
      }
      &.nav-previous {
        a {
          @extend .btn-reverse;
        }
      }
    }
  }
}
